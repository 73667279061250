@if (type === 'flat') {
<button type="button" mat-flat-button [color]="color" (click)="doClick()" [disabled]="isLoading || isDisabled"
  [tabindex]="tabindex" [class]="class">
  <ng-template [ngTemplateOutlet]="label"></ng-template>
</button>
}
@if (type === 'text') {
<button type="button" mat-button [color]="color" (click)="doClick()" [disabled]="isLoading || isDisabled"
  [tabindex]="tabindex" [class]="class">
  <ng-template [ngTemplateOutlet]="label"></ng-template>
</button>
}
@if (type === 'stroked') {
<button type="button" mat-stroked-button [color]="color" (click)="doClick()" [disabled]="isLoading || isDisabled"
  [tabindex]="tabindex" [class]="class">
  <ng-template [ngTemplateOutlet]="label"></ng-template>
</button>
}

<ng-template #label>
  <div class="flex flex-row items-center">
    @if (isLoading) {
      <mat-spinner diameter="20" class="mr-2" [ngClass]="{ 'order-last ml-2 !mr-0': spinnerPosition === 'last' }"></mat-spinner>
    } @else {
      <ng-content select="mat-icon"></ng-content>
    }
    <ng-content></ng-content>
  </div>
</ng-template>